<template>
  <b-card>
    <h4>Documenti</h4>
    <b-list-group class="mt-2">

      <b-list-group-item
        v-for="(file, index) in filesData"
        :key="index"
        class="d-flex justify-content-between align-items-center"
      >
        <span>{{ file.name }}</span>
        <b-button
          v-ripple.400="'rgba(255, 159, 67, 0.15)'"
          size="sm"
          variant="outline-primary"
          @click="download(file)"
        >
          Download
        </b-button>
      </b-list-group-item>

    </b-list-group>
  </b-card>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCard, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    filesData: {
      type: Array,
      required: true,
      default: () => {
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    download(file) {
      this.$store.dispatch('users/downloadFiles', {
        params: {
          link: file.link,
        },
        responseType: 'blob',
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Download iniziato',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', file.name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
