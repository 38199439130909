var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.show,"variant":"transparent","opacity":0.85,"blur":"2px","rounded":"sm"}},[_c('b-card',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)},"click":function($event){_vm.submitType = $event}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Vecchia Password","label-for":"account-old-password"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-old-password","name":"old-password","type":_vm.passwordFieldTypeOld,"placeholder":"Vecchia Password","state":errors.length > 0 ? false:null},model:{value:(_vm.passwordValueOld),callback:function ($$v) {_vm.passwordValueOld=$$v},expression:"passwordValueOld"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Obbligatorio ")]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(errors)}},[_vm._v(" Obbligatorio ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"a-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|min:8|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-password","state":errors.length > 0 ? false:null,"type":"password","placeholder":"Password"},model:{value:(_vm.newPasswordValue),callback:function ($$v) {_vm.newPasswordValue=$$v},expression:"newPasswordValue"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(errors)}},[_vm._v(" Devi inserire almeno 8 caratteri, con almeno una lettera maiuscola e un carattere speciale ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Conferma Password","label-for":"ac-password"}},[_c('validation-provider',{attrs:{"name":"Conferma Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ac-password","state":errors.length > 0 ? false:null,"type":"password","placeholder":"Conferma Password"},model:{value:(_vm.RetypePassword),callback:function ($$v) {_vm.RetypePassword=$$v},expression:"RetypePassword"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(errors)}},[_vm._v(" Le password non corrispondono ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Salva modifica ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }