<template>
  <b-overlay
    id="overlay-background"
    :show="show"
    variant="transparent"
    :opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-card>
      <!-- form -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <b-form
          ref="form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
          @click="submitType = $event"
        >
          <b-row>
            <!-- old password -->
            <b-col md="6">

              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required"
              >
                <b-form-group
                  label="Vecchia Password"
                  label-for="account-old-password"
                >

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-old-password"
                      v-model="passwordValueOld"
                      name="old-password"
                      :type="passwordFieldTypeOld"
                      placeholder="Vecchia Password"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconOld"
                        class="cursor-pointer"
                        @click="togglePasswordOld"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Obbligatorio
                  </small>
                  <b-form-invalid-feedback :state="getValidationState(errors)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
            <!--/ old password -->
          </b-row>
          <b-row>
            <!-- new password -->
            <b-col md="6">
              <b-form-group
                label="Password"
                label-for="a-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8|password"
                >
                  <b-form-input
                    id="a-password"
                    v-model="newPasswordValue"
                    :state="errors.length > 0 ? false:null"
                    type="password"
                    placeholder="Password"
                  />
                  <b-form-invalid-feedback :state="getValidationState(errors)">
                    Devi inserire almeno 8 caratteri, con almeno una lettera maiuscola e un carattere speciale
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ new password -->

            <!-- retype password -->
            <b-col md="6">
              <b-form-group
                label="Conferma Password"
                label-for="ac-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Conferma Password"
                  rules="required|confirmed:Password"
                >

                  <b-form-input
                    id="ac-password"
                    v-model="RetypePassword"
                    :state="errors.length > 0 ? false:null"
                    type="password"
                    placeholder="Conferma Password"
                  />
                  <b-form-invalid-feedback :state="getValidationState(errors)">
                    Le password non corrispondono
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ retype password -->

            <!-- buttons -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                type="submit"
              >
                Salva modifica
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      required,
      alphaNum,
      integer,
      digits,

      show: false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      this.show = !this.show
      store.dispatch('users/updateIscritto', {
        id: getUserData().id,
        old_password: this.passwordValueOld,
        new_password: this.newPasswordValue,
        reset_password: true,
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.show = false
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.show = false
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
