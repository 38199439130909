<template>
  <b-overlay
    id="overlay-background"
    :show="show"
    variant="transparent"
    :opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-card>

      <!-- form -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <b-form
          ref="form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
          @click="submitType = $event"
        >

          <b-row>
            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Codice"
                label-for="code-input"
              >
                <b-form-input
                  id="code-input"
                  v-model="optionsLocal.code"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Tipo"
                  label-for="type-input"
                  invalid-feedback="Status is required"
                >
                  <v-select
                    v-model="optionsLocal.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :disabled="generalData.type === 'incaricato'"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--            <b-col-->
            <!--              lg="2"-->
            <!--              md="6"-->
            <!--              sm="12"-->
            <!--              cols="12"-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                #default="validationContext"-->
            <!--                name="status"-->
            <!--                rules="required"-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  label="Status"-->
            <!--                  label-for="status-input"-->
            <!--                  invalid-feedback="Status is required"-->
            <!--                >-->
            <!--                  <v-select-->
            <!--                    v-model="optionsLocal.active"-->
            <!--                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
            <!--                    :options="statusOptions"-->
            <!--                    :reduce="val => val.value"-->
            <!--                    :clearable="false"-->
            <!--                  />-->
            <!--                  <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
            <!--                    Obbligatorio-->
            <!--                  </b-form-invalid-feedback>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >

                <b-form-group
                  label="Nome"
                  label-for="name-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="name-input"
                    v-model="optionsLocal.name"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="last_name"
                rules="required"
              >

                <b-form-group
                  label="Cognome"
                  label-for="last_name-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="last_name-input"
                    v-model="optionsLocal.last_name"
                    :state="getValidationState(validationContext)"
                    required
                  />

                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Prefix"
                rules="required"
              >

                <b-form-group
                  label="Email"
                  label-for="email-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="email-input"
                    v-model="optionsLocal.email"
                    :state="getValidationState(validationContext)"
                    required
                  />

                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Prefix"
                rules="required"
              >

                <b-form-group
                  label="Prefisso"
                  label-for="prefix-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="prefix-input"
                    v-model="optionsLocal.prefix"
                    :state="getValidationState(validationContext)"
                    required
                  />

                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="phone_number"
                rules="required"
              >

                <b-form-group
                  label="Telefono"
                  label-for="phone-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="phone-input"
                    v-model="optionsLocal.phone_number"
                    :state="getValidationState(validationContext)"
                    required
                  />

                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="fiscal_code"
                rules="required"
              >
                <b-form-group
                  label="Codice fiscale"
                  label-for="fiscal_code-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="fiscal_code-input"
                    v-model="optionsLocal.fiscal_code"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <template v-if="optionsLocal.type === 'incaricato'">

            <!-- alert -->
            <b-col
              v-if="generalData.type === 'cliente'"
              cols="12"
              class="mt-75"
            >
              <b-alert
                show
                variant="success"
                class="mb-50"
              >
                <h4 class="alert-heading">
                  Stai per effettuare l'upgrade a incaricato!
                </h4>
                <div class="alert-body">
                  Dopo che avrai salvato dovrai effettuare l'accesso come incaricato
                </div>
              </b-alert>
            </b-col>
            <!--/ alert -->

            <div class="divider my-1">
              <div class="divider-text">
                Documenti & info personali
              </div>
            </div>

            <b-row>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="birthplace"
                  rules="required"
                >
                  <b-form-group
                    label="Nato a"
                    label-for="birthplace-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="birthplace-input"
                      v-model="optionsLocal.birthplace"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="province"
                  rules="required|alpha"
                >
                  <b-form-group
                    label="Provincia di"
                    label-for="g-province-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="g-province-input"
                      v-model="optionsLocal.province"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="nation_of_birth"
                  rules="required"
                >
                  <b-form-group
                    label="Stato"
                    label-for="nation_of_birth-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="nation_of_birth-input"
                      v-model="optionsLocal.nation_of_birth"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="gender"
                  rules="required"
                >
                  <b-form-group
                    label="Genere"
                    label-for="gender-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="optionsLocal.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="identity_card"
                  rules="required"
                >
                  <b-form-group
                    label="Documento"
                    label-for="identity_card-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="identity_card-input"
                      v-model="optionsLocal.identity_card"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="6"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="identity_card_number"
                  rules="required"
                >
                  <b-form-group
                    label="Numero documento"
                    label-for="identity_card_number-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="identity_card_number-input"
                      v-model="optionsLocal.identity_card_number"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="public_authority"
                  rules="required"
                >
                  <b-form-group
                    label="Rilasciato da"
                    label-for="public_authority-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="public_authority-input"
                      v-model="optionsLocal.public_authority"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="4"
                md="6"
                sm="12"
                cols="12"
              >
                <b-form-group
                  label="Rilasciato il"
                  label-for="release_date"
                  invalid-feedback="Obbligatorio"
                >
                  <cleave
                    id="release_date-input"
                    v-model="optionsLocal.release_date"
                    class="form-control"
                    :raw="false"
                    :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                    placeholder="GG/MM/AAAA"
                  />
                  <b-form-invalid-feedback :state="errors.release_date">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="6"
                sm="12"
                cols="12"
              >
                <b-form-group
                  label="Scadenza il"
                  label-for="expiration_date"
                  invalid-feedback="Obbligatorio"
                >
                  <cleave
                    id="expiration_date-input"
                    v-model="optionsLocal.expiration_date"
                    class="form-control"
                    :raw="false"
                    :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                    placeholder="GG/MM/AAAA"
                  />
                  <b-form-invalid-feedback :state="errors.expiration_date">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="6"
                sm="12"
                cols="12"
              >
                <b-form-group
                  label="Nato il"
                  label-for="birthday"
                  invalid-feedback="Obbligatorio"
                >
                  <cleave
                    id="birthday-input"
                    v-model="optionsLocal.birthday"
                    class="form-control"
                    :raw="false"
                    :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                    placeholder="GG/MM/AAAA"
                  />
                  <b-form-invalid-feedback :state="errors.birthday">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="spouse"
                  rules="required"
                >
                  <b-form-group
                    label="Sposata/o"
                    label-for="spouse-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="optionsLocal.spouse"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="first_name_spouse"
                  rules=""
                >
                  <b-form-group
                    label="Nome coniuge"
                    label-for="first_name_spouse-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="first_name_spouse-input"
                      v-model="optionsLocal.first_name_spouse"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="last_name_spouse"
                  rules=""
                >
                  <b-form-group
                    label="Cognome coniuge"
                    label-for="last_name_spouse-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="last_name_spouse-input"
                      v-model="optionsLocal.last_name_spouse"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="inps"
                  rules="required"
                >
                  <b-form-group
                    label="Inps"
                    label-for="inps-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="optionsLocal.inps"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="3"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="retired"
                  rules="required"
                >
                  <b-form-group
                    label="Pensionata/o"
                    label-for="retired-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <v-select
                      v-model="optionsLocal.retired"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="6"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="iban"
                  rules=""
                >
                  <b-form-group
                    label="Iban"
                    label-for="iban-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="iban-input"
                      v-model="optionsLocal.iban"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

          </template>
          <template v-else>
            <b-row>

              <b-col
                lg="2"
                md="6"
                sm="12"
                cols="12"
              >

                <validation-provider
                  #default="validationContext"
                  name="azienda"
                  rules=""
                >
                  <b-form-group
                    label="Azienda"
                    label-for="company-input"
                  >
                    <v-select
                      v-model="optionsLocal.company"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siNoOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      @input="optionsLocal.business_name = null;
                              optionsLocal.vat = null;"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      Obbligatorio
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <template v-if="optionsLocal.company">
                <b-col
                  lg="3"
                  md="6"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="business_name"
                    rules="required"
                  >
                    <b-form-group
                      label="Nome Azienda"
                      label-for="business_name-input"
                      invalid-feedback="Obbligatorio"
                    >
                      <b-form-input
                        id="business_name-input"
                        v-model="optionsLocal.business_name"
                        :state="getValidationState(validationContext)"
                        required
                        :readonly="!optionsLocal.company"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  lg="3"
                  md="6"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="vat"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="P.IVA"
                      label-for="vat-input"
                      invalid-feedback="Obbligatorio e numerico"
                    >
                      <b-form-input
                        id="vat-input"
                        v-model="optionsLocal.vat"
                        :state="getValidationState(validationContext)"
                        required
                        :readonly="!optionsLocal.company"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  lg="3"
                  md="6"
                  sm="12"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="uniquecode"
                    rules="min:6"
                  >
                    <b-form-group
                      label="Codice univoco"
                      label-for="uniquecode-input"
                      invalid-feedback="Minimo 6 caratteri"
                    >
                      <b-form-input
                        id="uniquecode-input"
                        v-model="optionsLocal.uniquecode"
                        :state="getValidationState(validationContext)"
                        required
                        :readonly="!optionsLocal.company"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </template>

            </b-row>

          </template>

          <b-row>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                type="submit"
              >
                Salva
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormInvalidFeedback, BAlert, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import {
  required, alphaNum, integer, digits,
} from '@validations'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config'
import { useRouter } from '@core/utils/utils'
import ability from '@/libs/acl/ability'
import useJwt from '@/auth/jwt/useJwt'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormInvalidFeedback,
    BAlert,
    BOverlay,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    siNoOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      profileFile: null,

      required,
      alphaNum,
      integer,
      digits,
    }
  },
  methods: {},
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()
    const { router } = useRouter()

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const show = ref(false)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    const errors = ref({
      release_date: null,
      expiration_date: null,
      birthday: null,
    })

    const blankGeneralData = {
      id: props.generalData.id,
      active: props.generalData.active,
      code: props.generalData.code,
      name: props.generalData.personal_data.name,
      last_name: props.generalData.personal_data.last_name,
      prefix: props.generalData.personal_data.prefix,
      phone_number: props.generalData.personal_data.phone_number,
      fiscal_code: props.generalData.personal_data.fiscal_code,
      company: props.generalData.company,
      disabled: props.generalData.disabled,
      email: props.generalData.email,
      role: props.generalData.role,
      sponsor: props.generalData.sponsor,
      type: props.generalData.type,
      upline: props.generalData.upline,
      birthday: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.birthday : null,
      birthplace: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.birthplace : null,
      expiration_date: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.expiration_date : null,
      gender: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.gender : null,
      identity_card: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.identity_card : null,
      identity_card_number: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.identity_card_number : null,
      inps: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.inps : null,
      nation_of_birth: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.nation_of_birth : null,
      province: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.province : null,
      public_authority: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.public_authority : null,
      release_date: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.release_date : null,
      retired: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.retired : null,
      iban: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.iban : null,
      spouse: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.spouse : null,
      first_name_spouse: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.first_name_spouse : null,
      last_name_spouse: props.generalData.personal_data.incaricato ? props.generalData.personal_data.incaricato.last_name_spouse : null,
      vat: props.generalData.personal_data.cliente ? props.generalData.personal_data.cliente.vat : null,
      uniquecode: props.generalData.personal_data.cliente ? props.generalData.personal_data.cliente.uniquecode : null,
      business_name: props.generalData.personal_data.cliente ? props.generalData.personal_data.cliente.business_name : null,
    }

    const optionsLocal = ref(JSON.parse(JSON.stringify(blankGeneralData)))
    const resetitemData = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(blankGeneralData))
    }

    function checkRequired() {
      errors.value.release_date = !!optionsLocal.value.release_date
      errors.value.expiration_date = !!optionsLocal.value.expiration_date
      errors.value.birthday = !!optionsLocal.value.birthday
      return !!optionsLocal.value.release_date && !!optionsLocal.value.expiration_date && !!optionsLocal.value.birthday
    }

    const onSubmit = () => {
      if (checkRequired()) {
        show.value = !show.value
        store.dispatch('users/updateIscritto', optionsLocal.value)
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (optionsLocal.value.type !== props.generalData.type) {
              // Remove userData from localStorage
              // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
              localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
              localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

              // Remove userData from localStorage
              localStorage.removeItem('userData')

              // Reset ability
              ability.update(initialAbility)

              // Redirect to login page
              router.push({ name: 'auth-login' })
            } else {
              emit('refetch-data')
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }
            show.value = false
          })
          .catch(error => {
            console.log(error.response)
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            show.value = false
          })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      show,
      resetitemData,
      optionsLocal,
      errors,
      onSubmit,
      refInputEl,
      previewEl,
      inputImageRenderer,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
