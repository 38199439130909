<template>
  <b-overlay
    id="overlay-background"
    :show="show"
    variant="transparent"
    :opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-card>
      <h4>Metodo di pagamento</h4>
      <b-alert
        variant="danger"
        show
        class="text-center"
      >
        <div class="alert-body">
          <span><strong>Attenzione</strong> Inserendo una nuova carta eliminerai il tuo metodo di pagamento attuale</span>
        </div>
      </b-alert>
      <label class="mt-1">Intestatario carta</label>
      <input
        id="card-holder-name"
        v-model="name"
        type="text"
        class="form-control mb-2"
      >
      <label class="mt-1">Carta</label>
      <div
        id="card-element"
        class="mt-1"
      />
      <button
        id="add-card-button"
        class="btn btn-primary mt-3"
        @click="submitPaymentMethod()"
      >
        Imposta metodo di pagamento
      </button>
    </b-card>
  </b-overlay>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
  import {
    BListGroup, BListGroupItem, BCard, BButton, BAlert, BOverlay,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { ref } from '@vue/composition-api'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    components: {
      BCard,
      BAlert,
      BOverlay,
    },
    directives: {
      Ripple,
    },
    props: {
      userData: {
        type: Object,
        required: true,
        default: () => {
        },
      },
    },
    data() {
      return {
        stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,

        stripe: '',
        elements: '',
        card: '',

        intentToken: '',
        name: '',
        addPaymentStatus: 0,
        addPaymentStatusError: '',

        show: false,
      }
    },
    mounted() {
      this.loadIntent()

      this.includeStripe('js.stripe.com/v3/', () => {
        this.configureStripe()
      })
    },
    methods: {
      loadIntent() {
        this.$store.dispatch('users/setupIntent')
          .then(response => {
            this.intentToken = response.data.data
          })
      },
      submitPaymentMethod() {
        this.show = !this.show
        this.addPaymentStatus = 1
        this.stripe.confirmCardSetup(
          this.intentToken.client_secret, {
            payment_method: {
              card: this.card,
              billing_details: {
                name: this.name,
              },
            },
          },
        )
          .then(result => {
            if (result.error) {
              this.addPaymentStatus = 3
              this.addPaymentStatusError = result.error.message
              this.show = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: result.error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            } else {
              this.savePaymentMethod(result.setupIntent.payment_method)
              this.addPaymentStatus = 2
              this.card.clear()
              this.name = ''
            }
          })
      },

      savePaymentMethod(method) {
        this.$store.dispatch('users/addPaymentMethod', { payment_method: method })
          .then(response => {
            this.$emit('refetch-data')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            setTimeout(() => {
              window.location.reload()
            }, 1500)
            this.show = false
          })
      },

      /*
          Includes Stripe.js dynamically
      */
      includeStripe(URL, callback) {
        const documentTag = document
        const tag = 'script'
        const object = documentTag.createElement(tag)
        const scriptTag = documentTag.getElementsByTagName(tag)[0]
        object.src = `//${URL}`
        if (callback) {
          object.addEventListener('load', e => {
            callback(null, e)
          }, false)
        }
        scriptTag.parentNode.insertBefore(object, scriptTag)
      },

      /*
      Configures Stripe by setting up the elements and
      creating the card element.
  */
      configureStripe() {
        this.stripe = Stripe(this.stripeAPIToken)

        this.elements = this.stripe.elements()
        this.card = this.elements.create('card')

        this.card.mount('#card-element')
      },
    },
  }
</script>

<style lang="scss" scoped>
    #cvv {
        width: auto;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
