<template>
  <b-overlay
    id="overlay-background"
    :show="show"
    variant="transparent"
    :opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-card>
      <b-row>
        <b-col>
          <h5 v-if="userData.cash_subscription">
            Il tuo attuale autoconsumo mensile comprende
            <b-badge variant="success">
              {{ userData.cash_subscription.quantity }}
            </b-badge>
            scatol{{ userData.cash_subscription.quantity > 1 ? 'e' : 'a' }} di caffè
            <b-badge variant="success">
              {{ userData.cash_subscription.name.replaceAll('_', ' ') }}
            </b-badge><br>
            Stato pagamento:
            <b-badge :variant="userData.subscription === 'Da pagare' ? 'warning' : 'success'">
              {{ userData.subscription }}
            </b-badge><br>
            Modalità di pagamento:
            <b-badge variant="danger">
              contanti
            </b-badge>
          </h5>
          <h5 v-else-if="stripeData.user && Object.keys(stripeData.user).length > 1">
            Il tuo attuale autoconsumo mensile comprende
            <b-badge variant="success">
              {{ stripeData.user.quantity }}
            </b-badge>
            scatol{{ stripeData.user.quantity > 1 ? 'e' : 'a' }} di caffè
            <b-badge variant="success">
              {{ stripeData.user.name.replaceAll('_', ' ') }}
            </b-badge>
            {{ stripeData.user.ends_at ? 'Il tuo autoconsumo sarà annullato il ' : 'Il prossimo rinnovo sarà il' }}
            {{ ' ' + stripeData.next_renew }}
            <b-badge :variant="stripeData.user.stripe_status === 'past_due' ? 'danger' : 'success'">
              {{ stripeData.user.stripe_status === 'past_due' ? 'Scaduto' : 'Attivo' }}
            </b-badge>
          </h5>
          <h5 v-else>
            Non hai autoconsumi attivi
          </h5>
        </b-col>

      </b-row>

      <div class="divider my-1">
        <div class="divider-text">
          Aggiorna il tuo autoconsumo
        </div>
      </div>

      <b-form
        @submit.prevent="onSubmit"
      >
        <b-row>

          <b-col cols="12">
            <b-alert
              variant="primary"
              show
              class="text-center"
            >
              <div class="alert-body">
                <span><strong>Attenzione</strong> Premendo su conferma cambierai il prezzo del tuo autoconsumo mensile.</span>
              </div>
            </b-alert>
          </b-col>

          <b-col
            v-if="optionsLocal.typeOfPayment === 'cash'"
            cols="12"
          >
            <b-alert
              variant="danger"
              show
              class="text-center"
            >
              <div
                class="alert-body"
              >
                <span><strong>Attenzione</strong> Per confermare il tuo autoconsumo mensile dovrai effettuare il pagamento in contanti presso la nostra sede, in Via calamandrei 120/i 52100 Arezzo.</span>
              </div>
            </b-alert>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
            class="mt-1"
          >
            <v-select
              v-model="optionsLocal.typeOfPayment"
              :options="paymentOptions"
              :clearable="false"
              :reduce="val => val.value"
              @change="handleCaffeOptionsVisualization"
            />
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
            class="mt-1"
          >
            <v-select
              v-model="optionsLocal.plan"
              :options="handleCaffeOptionsVisualization()"
              :clearable="false"
              :reduce="val => val"
            />
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
            class="mt-1"
          >
            <v-select
              v-model="optionsLocal.quantity"
              :options="quantityOptions"
              :clearable="false"
            />
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              Conferma
            </b-button>
          </b-col>

          <b-col
            lg="3"
            md="6"
            sm="12"
            cols="12"
            class="mt-1"
          >
            <div class="bg-light-primary rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ getSubscriptionPrice().total_sum }}
                </h2>
                <sub class="text-body">
                  <small>/ mese</small>
                </sub>
              </div>
              <b-badge
                variant="light-primary"
                pill
              >
                Totale
              </b-badge>
            </div>
          </b-col>

          <b-col
            lg="12"
            md="12"
            sm="12"
            cols="12"
            class="mt-4"
          >
            <b-link
              v-if="userData.cash_subscription"
              class="text-danger"
              @click="swalConfirmDelete(stripeData.user.stripe_id)"
            >
              Cancella autoconsumo
            </b-link>
            <b-link
              v-else-if="stripeData && stripeData.user && stripeData.user.ends_at === null"
              class="text-danger"
              @click="swalConfirmDelete(stripeData.user.stripe_id)"
            >
              Cancella autoconsumo
            </b-link>
          </b-col>

        </b-row>
      </b-form>

      <stripe-checkout
        v-if="userData && optionsLocal"
        ref="checkoutRef"
        mode="subscription"
        :pk="publishableKey"
        :session-id="sessionId"
        @loading="v => loading = v"
      />
      <!--      <button @click="submit">-->
      <!--        Subscribe!-->
      <!--      </button>-->
    </b-card>
  </b-overlay>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import {
  BButton, BForm, BRow, BCol, BCard, BBadge, BAlert, BOverlay, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { StripeCheckout } from '@vue-stripe/vue-stripe'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Swal from 'sweetalert2'

export default {
  components: {
    StripeCheckout,
    vSelect,
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
    BBadge,
    BAlert,
    BOverlay,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
      default: () => {},
    },
    stripeData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props, { emit, refs }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)
    const sessionId = ref(null)

    const publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    const loading = ref(false)
    const lineItems =  ref([])
    const successURL = process.env.VUE_APP_PAYMENT_SUCCESS
    const cancelURL = process.env.VUE_APP_PAYMENT_ERROR
    const paymentIntent = ref(null)
    const stripe = ref(null)

    const quantityOptions = [1, 2, 3, 4, 5, 10]
    const paymentOptions = [
      {
        label: 'Carta',
        value: 'card',
      },
      {
        label: 'Contanti',
        value: 'cash',
      },
    ]
    const caffeOptions = [
      {
        label: 'Forte',
        value: 'forte',
        price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_3,
        price: 37,
      },
      {
        label: 'Forte ritiro in sede',
        value: 'forte_con_ritiro_in_sede',
        price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_5,
        price: 37,
      },
      {
        label: 'Decaffeinato',
        value: 'decaffeinato',
        price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_4,
        price: 40,
      },
      {
        label: 'Decaffeinato ritiro in sede',
        value: 'decaffeinato_con_ritiro_in_sede',
        price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_6,
        price: 40,
      },
      {
        label: 'Dolce',
        value: 'dolce',
        price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_4,
        price: 40,
      },
      {
        label: 'Dolce ritiro in sede',
        value: 'dolce_con_ritiro_in_sede',
        price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_6,
        price: 40,
      },
      {
        label: 'Orzo',
        value: 'orzo',
        price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_4,
        price: 40,
      },
      {
        label: 'Orzo ritiro in sede',
        value: 'orzo_con_ritiro_in_sede',
        price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_6,
        price: 40,
      },
    ]

    const blankPlanData = {
      id: props.stripeData.user ? props.stripeData.user.stripe_id : null,
      plan: props.stripeData.user ? caffeOptions.find(value => value.value === props.stripeData.user.name) : null,
      quantity: props.stripeData.user ? props.stripeData.user.quantity : null,
    }

    const optionsLocal = ref(JSON.parse(JSON.stringify(blankPlanData)))
    const resetitemData = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(blankPlanData))
    }

    const onSubmit = () => {
      //Controllo se il pagamento viene fatto con carta
      if (optionsLocal.value.typeOfPayment === 'card') {
        if (Object.keys(props.stripeData.user).length > 1) {
          show.value = true
          const sendData = { ...optionsLocal.value }
          const plan = caffeOptions.find(value => value.value === sendData.plan.value)
          sendData.plan = plan.value
          sendData.price_id = plan.price_id
          store.dispatch('users/updateSubscription', sendData)
              .then(response => {
                emit('refetch-data')
                show.value = false
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                console.log(error)
                toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                show.value = false
              })
        } else {
          store.dispatch('ecommerce/paymentSession', {
            line_items: [{
              price: optionsLocal.value.plan.price_id,
              quantity: optionsLocal.value.quantity,
            }],
            mode: 'subscription',
            payment_method: 'carta',
            metadata: [{
              plan_name: optionsLocal.value.plan.value,
            }],
            price: optionsLocal.value.plan.price * optionsLocal.value.quantity
          })
              .then(response => {
                sessionId.value = response.data.data.id
                // You will be redirected to Stripe's secure checkout page
                refs.checkoutRef.redirectToCheckout()
              })
        }
      } else {
        show.value = true
        let sendData = { ...optionsLocal.value }
        const plan = caffeOptions.find(value => value.value === sendData.plan.value)
        delete sendData.typeOfPayment
        sendData.type_of_payment = optionsLocal.value.typeOfPayment
        sendData.plan = plan.value
        sendData.price_id = plan.price_id
        sendData.id = props.userData.id
        const updateOrCreate = props.userData.cash_subscription ? 'update' : 'create'
        store.dispatch(`users/${updateOrCreate}Subscription`, sendData)
            .then(response => {

              emit('refetch-data')
              show.value = false
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              console.log(error)
              toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              show.value = false
            })
      }
    }

    //Per gli autoconsumi in contanti lascio solo il ritoro in sede
    const handleCaffeOptionsVisualization = () => {
      if (optionsLocal.value.typeOfPayment === 'cash') {
        return caffeOptions.filter(option => option.value.includes('in_sede'))
      }
      return caffeOptions
    }

    const swalConfirmDelete = id => {
      Swal.fire({
        title: 'Stai eliminando il tuo autocosumo',
        text: 'Non potrai più ricevere i V.P.C. derivanti dall\'attività sei sicura/o',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si cancella',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('users/deleteSubscription', id)
                  .then(() => {
                    emit('refetch-data')
                    Swal.fire({
                      icon: 'success',
                      title: 'Autoconsumo cancellato',
                      text: 'I tuoi V.P.C. generati non verranno più conteggiati.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: 'Annullato',
                text: 'Operazione annullata',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
            return result.value
          })
    }

    const getSubscriptionPrice = () => {
      if (Object.keys(props.stripeData).length > 0 && Object.keys(optionsLocal.value).length > 0 && optionsLocal.value && optionsLocal.value.plan && optionsLocal.value.quantity) {
        const price = props.stripeData.plans.data.find(value => value.id === optionsLocal.value.plan.price_id)
        let totalPrice = price.tiers.find(value => value.up_to >= optionsLocal.value.quantity)
        if (totalPrice === undefined) {
          totalPrice = price.tiers.find(value => value.up_to === null)
        }

        return {
          total: totalPrice.unit_amount / 100 * optionsLocal.value.quantity,
          flat: totalPrice.flat_amount / 100,
          total_sum: totalPrice.unit_amount / 100 * optionsLocal.value.quantity + totalPrice.flat_amount / 100,
        }
      }
      return {
        total: 0,
        flat: 0,
        total_sum: 0,
      }
    }

    const submitSwitch = () => {
      show.value = !show.value
      store.dispatch('ecommerce/paymentIntent', {
        amount: getSubscriptionPrice(),
      })
          .then(response => {
            paymentIntent.value = response.data.data
            stripe.value = Stripe(publishableKey)
            stripe.value
                .confirmCardPayment(paymentIntent.value.client_secret, {})
                .then(() => {
                  onSubmit()
                  show.value = !show.value
                  // Handle result.error or result.paymentIntent
                })
          })
    }

    const submit = () => {
      this.$store.dispatch('ecommerce/paymentSession', {
        line_items: [{
          price: optionsLocal.value.plan.price_id,
          quantity: optionsLocal.value.quantity,
        }],
        mode: 'subscription',
        payment_method: 'carta',
      })
          .then(response => {
            sessionId.value = response.data.data.id
            // You will be redirected to Stripe's secure checkout page
            refs.checkoutRef.redirectToCheckout()
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      sessionId,

      publishableKey,
      loading,
      lineItems,
      successURL,
      cancelURL,
      paymentIntent,
      stripe,

      show,
      resetitemData,
      optionsLocal,
      onSubmit,
      quantityOptions,
      paymentOptions,
      caffeOptions,

      refFormObserver,
      getValidationState,
      resetForm,
      handleCaffeOptionsVisualization,

      swalConfirmDelete,
      getSubscriptionPrice,
      submitSwitch,
      submit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
