<template>
  <b-overlay
    id="overlay-background"
    :show="show"
    variant="transparent"
    :opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-card>

      <!-- form -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <b-form
          ref="form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
          @click="submitType = $event"
        >

          <b-row
            v-for="(indirizzo, index) in optionsLocal.addresses"
            :key="indirizzo.id"
          >

            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
              class="mb-1"
            >
              Indirizzo di {{ indirizzo.type }}
            </b-col>

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="number"
                rules="required"
              >

                <b-form-group
                  label="Via"
                  label-for="street-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="street-input"
                    v-model="optionsLocal.addresses[index].street"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="number"
                rules="required"
              >

                <b-form-group
                  label="Numero"
                  label-for="number-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="number-input"
                    v-model="optionsLocal.addresses[index].number"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="3"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="city"
                rules="required"
              >

                <b-form-group
                  label="Città"
                  label-for="city-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="city-input"
                    v-model="optionsLocal.addresses[index].city"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="province"
                rules="required|alpha"
              >

                <b-form-group
                  label="Provincia"
                  label-for="province-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="province-input"
                    v-model="optionsLocal.addresses[index].province"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="zip"
                rules="required"
              >

                <b-form-group
                  label="CAP"
                  label-for="zip-input"
                  invalid-feedback="Obbligatorio"
                >

                  <b-form-input
                    id="zip-input"
                    v-model="optionsLocal.addresses[index].zip"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <b-row>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                type="submit"
              >
                Salva
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    addressData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  methods: {
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const show = ref(false)

    const blankAddressData = {
      id: props.addressData.id,
      addresses: props.addressData.addresses,
    }

    const optionsLocal = ref(JSON.parse(JSON.stringify(blankAddressData)))
    const resetitemData = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(blankAddressData))
    }

    const onSubmit = () => {
      show.value = !show.value
      store.dispatch('users/updateIscritto', optionsLocal.value)
        .then(response => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            window.location.reload()
          }, 1500)
          show.value = false
        })
        .catch(error => {
          console.log(error.response)
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          show.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      show,

      resetitemData,
      optionsLocal,
      onSubmit,
      refInputEl,
      previewEl,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
