<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Generali</span>
      </template>

      <account-setting-general
        v-if="userData"
        :general-data="userData"
        :type-options="typeOptions"
        :status-options="statusOptions"
        :gender-options="genderOptions"
        :si-no-options="siNoOptions"
        @refetch-data="fetchUser"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Cambio Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- Indirizzi -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Indirizzi</span>
      </template>

      <account-setting-addresses
        v-if="userData"
        :address-data="userData"
      />
    </b-tab>

    <!-- Autoconsumo -->
    <b-tab
      v-if="$can('store', 'Orders') && stripeData"
      :disabled="stripeData.user.stripe_id == null"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="RefreshCcwIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Autoconsumo</span>
      </template>

      <account-setting-subcription
        v-if="userData && stripeData"
        :user-data="userData"
        :stripe-data.sync="stripeData"
        @refetch-data="refetchData"
      />
    </b-tab>

    <!-- Documenti -->
    <b-tab v-if="$can('store', 'Orders')">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Documenti</span>
      </template>

      <account-setting-documents
        v-if="filesData"
        :files-data="filesData"
      />
    </b-tab>

    <!-- Carta -->
    <b-tab v-if="$can('store', 'Orders')">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="CreditCardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Carta</span>
      </template>

      <account-setting-card
        v-if="userData"
        :user-data="userData"
        @refetch-data="fetchSubscriptions"
      />
    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import { getUserData } from '@/auth/utils'
import CryptoJS from 'crypto-js'
import { ref } from '@vue/composition-api'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import AccountSettingSubcription from '@/views/utente-loggato/account-setting/AccountSettingSubcription.vue'
import AccountSettingDocuments from '@/views/utente-loggato/account-setting/AccountSettingDocuments.vue'
import ability from '@/libs/acl/ability'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountSettingCard from '@/views/utente-loggato/account-setting/AccountSettingCard.vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingAddresses from './AccountSettingAddresses.vue'

// Notification

export default {
  components: {
    AccountSettingCard,
    AccountSettingDocuments,
    AccountSettingSubcription,
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingAddresses,
  },
  data() {
    return {
      options: {},
    }
  },
  setup() {
    const toast = useToast()

    const userData = ref(null)
    const stripeData = ref(null)
    const filesData = ref(null)

    const {
      // Options
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
      //

      // Extra Filters
      //
    } = useUserList()

    const fetchUser = () => {
      const user = getUserData()
      store.dispatch('users/fetchIscritto', user.id)
        .then(response => {
          userData.value = response.data.data
          userData.value.ability = user.ability
          userData.value.extras = user.extras
          localStorage.setItem('userData', CryptoJS.AES.encrypt(JSON.stringify(userData.value), process.env.VUE_APP_CRYPT)
            .toString())
        })
        .catch(error => {
          console.log(error.response)
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    fetchUser()

    const fetchSubscriptions = () => {
      if (ability.can('store', 'Orders')) {
        store.dispatch('users/fetchSubscriptions')
          .then(response => {
            stripeData.value = response.data.data
          })
          .catch(error => {
            console.log(error.response)
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }
    fetchSubscriptions()

    const fetchFiles = () => {
      if (ability.can('store', 'Orders')) {
        store.dispatch('users/fetchFiles')
          .then(response => {
            filesData.value = response.data.data
          })
          .catch(error => {
            console.log(error.response)
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }
    fetchFiles()

    const refetchData = () => {
      fetchUser()
      fetchSubscriptions()
    }

    return {
      userData,
      stripeData,
      filesData,
      fetchUser,
      fetchSubscriptions,
      fetchFiles,
      refetchData,

      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
    }
  },
}
</script>
